import axios from 'axios';
import { Loading, Message } from 'element-ui';
import { delCookie } from '@/plugins/utils';

let base = 'https://api.lvshuhualv.com';
let isDev = process.env.NODE_ENV === 'development';
let authToken = '';

if (isDev) {
    base = 'https://ikuai0.haloom.cc:7991';
    authToken = '72e15c01-0519-4d3f-a941-1b414315b979';
    localStorage.setItem('authToken', authToken);
}

let ajax = axios.create({
    baseURL: base,
    timeout: 60000,
    withCredentials: true,
});

let cancelToken = axios.CancelToken;
// 添加请求拦截器
ajax.interceptors.request.use(
    function (config) {
        !config.headers.authToken &&
            Object.assign(config.headers, {
                authToken: isDev
                    ? authToken
                    : localStorage.getItem('authToken'),
            });

        config.cancelToken = new cancelToken(c => {
            //回调回去取消方法
            if (config.params) {
                config.params.abort && config.params.abort(c);
            } else if (config.abort) {
                config.abort(c);
            }
        });
        if (config.params && config.params.abort) {
            delete config.params.abort;
        } else if (config.abort) {
            delete config.abort;
        }

        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
ajax.interceptors.response.use(
    res => {
        // 对响应数据做点什么
        const code = res.data.code || 200;
        if (code === 401) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('access_token');
            delCookie('flower_info');
            console.log(window.location.host);
            window.location.href = '/#/login';
            Message({
                type: 'error',
                message: res.data.msg,
            });
        } else if (code !== 200) {
            if (code === 403) {
                return Promise.reject(res.data);
            }
            if (code == 2001) {
                return res.data;
            }
            Message({
                type: 'error',
                message: res.data.msg,
            });
            return Promise.reject(res.data);
        } else {
            return res.data;
        }
    },
    error => {
        // 对响应错误做点什么
        let { message, code } = error;
        console.log(message);
        console.log('err' + error);

        if (message == 'Network Error') {
            message = '接口连接异常';
        } else if (message.includes('timeout') && code == 'ECONNABORTED') {
            message = '接口请求超时';
        } else if (message == 'Request failed with status code 403') {
            return Promise.reject(error);
        } else if (message.includes('Request failed with status code')) {
            message = '接口' + message.substr(message.length - 3) + '异常';
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);
const install = function (Vue) {
    Vue.prototype.$ajax = ajax;
    Vue.prototype.$axios = axios;
    Vue.prototype.$editAddr = 'https://plat.lvshuhualv.com/edit/#/';
};

export default {
    install,
};
